import { render, staticRenderFns } from "./PWAPrompt.vue?vue&type=template&id=4d296c9e&scoped=true&"
import script from "./PWAPrompt.vue?vue&type=script&lang=js&"
export * from "./PWAPrompt.vue?vue&type=script&lang=js&"
import style0 from "./PWAPrompt.vue?vue&type=style&index=0&id=4d296c9e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d296c9e",
  null
  
)

export default component.exports